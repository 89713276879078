export default () => {
  const { theme } = window;
  const {
    customer,
    cart = { tags: [], attributes: {} },
    emrENV,
    routes,
    enablePaidRx,
  } = theme || {};

  const IS_STAGING = routes?.permanent_domain?.includes("staging");
  const EMR_BASE_URL = `https://us-west2-pet-emr-${emrENV}.cloudfunctions.net`;
  const EMR_URL = `${EMR_BASE_URL}/CustomerV0`;
  const BUCKET_URL = `https://firebasestorage.googleapis.com/v0/b/pet-emr-${emrENV}.appspot.com/o`;

  const hasPaidRx = (cartAttributes: Record<string, unknown>) => {
    return ["purchase_rx", "request_rx", "get_rx"].some(
      (key) => key in cartAttributes
    );
  };

  const isShelter = customer?.tags.some((tag) => tag.includes("Shelter:"));
  const isPaidRx = hasPaidRx(cart.attributes) && enablePaidRx;

  return {
    ...theme,
    hasPaidRx,
    isShelter,
    isPaidRx,
    IS_STAGING,
    EMR_BASE_URL,
    EMR_URL,
    BUCKET_URL,
  };
};
